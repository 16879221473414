import React, { useState, useEffect } from "react";
import EventCard from "../components/Card";
import { Helmet } from "react-helmet";
import slider from "../img/1.jpeg";
import Axios from "axios";

export default function Events({ history }) {
  const [eventList, setEventList] = useState([]);

  useEffect(() => {
    Axios.get(`https://api.xfactorproductions.ng/wp-json/wp/v2/event?per_page=50`).then(
      (res) => {   
        const { data } = res;
        let eventArray = [];
        data?.map((e) =>
          eventArray.push({
            id: e.id,
            title: e.title?.rendered,
            content: e.content?.rendered,
            date: e.date,
            image: e.better_featured_image?.source_url,
            guest: e.acf?.guest,
            slug: e.slug,
            except: e.acf?.role,
          })
        );
        setEventList(eventArray);
      }
    );
  }, []);

  return (
    <>
      <Helmet>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta http-equiv="X-UA-Compatible" content="ie=edge" />
        <title>Blog - XFP Productions Ltd</title>
        <link rel="canonical" href="https://xfactorproductions.ng/blog" />
      </Helmet>
      <section
        id="hero-1"
        data-parallax="scroll"
        style={{ backgroundImage: `url(${slider})` }}
      >
        <div className="title">
          <div className="container">
            <h2 style={{ color: "#fff" }}>
              {" "}
              Blog{" "}
            </h2>
          </div>
        </div>
      </section>
      <section id="blog" class="blog" style={{ marginTop: '50px' }}>
        <div class="container">
          <div class="row">
            {eventList?.map((e, i) => (
              <div class="col-lg-4  col-md-6 d-flex align-items-stretch">
                <EventCard key={i} {...e} />
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
