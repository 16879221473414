import { Helmet } from "react-helmet";
import slider from "../img/Booking.jpeg";
import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect, useState } from "react";

export default function Booking() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <>
      <Helmet>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta http-equiv="X-UA-Compatible" content="ie=edge" />

        <title>Book your spot with XFP Productions</title>
        <meta name="description" content="Book XFP Productions for an extraordinary entertainment experience" />
        <meta name="keywords" content="XFP Productions" />
        <link rel="canonical" href="http://xfactorproductions.ng/booking" />
      </Helmet>
      <section
        id="hero-1"
        data-parallax="scroll"
        style={{ backgroundImage: `url(${slider})` }}
      >
        <div className="title">
          <div className="container">
            <h2 style={{ color: "#fff" }}> Book a Service </h2>
          </div>
        </div>
      </section>
      <section id="contact" class="contact">
        <div class="container" data-aos="fade-up">
          <div class="section-title">
            <h2>Contact</h2>
            <p>How can we be of service</p>
          </div>

          <div
            className="row justify-content-center d-flex "
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div className="col-lg-10 mt-5 mt-lg-0">
              <form
                action="forms/contact.php"
                method="post"
                role="form"
                className="php-email-form"
              >
                <div className="row">
                  <div className="col-md-6 form-group">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      id="name"
                      placeholder="Your Name"
                      required
                    />
                  </div>
                  <div className="col-md-6 form-group mt-3 mt-md-0">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      id="email"
                      placeholder="Your Email"
                      required
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label for="subject">Pick a Service</label>
                  <select class="form-control" name="subject" id="subject">
                    <option>Event Planning</option>
                    <option>Advertising</option>
                    <option>Talent Development</option>
                    <option>Media Consultancy</option>
                    <option>Artiste Management</option>
                    <option>Others</option>
                  </select>
                  <div class="validate"></div>
                </div>
                <div className="form-group mt-3">
                  <textarea
                    className="form-control"
                    name="message"
                    rows="8"
                    placeholder="Message"
                    required
                  ></textarea>
                </div>
                <div className="my-3">
                  <div className="loading">Loading</div>
                  <div className="error-message"></div>
                  <div className="sent-message">
                    Your message has been sent. Thank you!
                  </div>
                </div>
                <div className="text-center">
                  <button type="submit">Book a Service</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
