import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Axios from "axios";
import { useParams } from "react-router";
import { Markup } from "interweave";

export default function ViewEvent(props) {
  const [previousEvent, setPreviousEvent] = useState([]);
  const [tm, setTm] = useState();
  const { slug } = useParams();

  useEffect(() => {
    Axios.get(`https://api.xfactorproductions.ng/wp-json/wp/v2/event`).then(
      (res) => {
        const { data } = res;
        let eventPrevious = [];
        data?.map((e) =>
          eventPrevious.push({
            id: e.id,
            title: e.title?.rendered,
            date: e.date,
            image: e.better_featured_image?.source_url,
            slug: e.slug,
          })
        );
        setPreviousEvent(eventPrevious.slice(0, 5));
      }
    );
  }, []);

  useEffect(() => {
    Axios.get(
      `https://api.xfactorproductions.ng/wp-json/wp/v2/event/?slug=${slug}`
    ).then((res) => {
      const { data } = res;
      setTm({
        id: data[0].id,
        image: data[0].better_featured_image?.source_url,
        position: data[0].acf?.role,
        except: data[0].acf?.role,
        quote: data[0].acf?.quote,
        paragraph: data[0].acf?.paragraph,
        name: data[0].title?.rendered,
        all: data[0].content?.rendered,
        slug: data[0].slug,
        yoast_head_title: data[0].yoast_head_json?.title,
        yoast_head_description: data[0].yoast_head_json?.description,
      });
    });
  }, [slug]);
  return (
    <>
      <Helmet>
        <title>{tm?.yoast_head_title}</title>
        <meta name="description" content={tm?.yoast_head_description} />
        <link
          rel="canonical"
          href={`https://xfactorproductions.ng/blog/${tm?.slug}`}
        />
      </Helmet>
      <section id="blog-single" class="blog">
        <div class="container">
          <div className="section-title">
            <h2>Blog</h2>
            <p>{tm?.name}</p>
          </div>

          <div class="row">
            <div class="col-lg-8 entries">
              <article class="entry entry-single">
                <div class="entry-img">
                  <img src={tm?.image} alt="" class="img-fluid" />
                </div>

                <h2 class="entry-title">{tm?.name}</h2>

                <div class="entry-content">
                  <p>
                    <Markup content={tm?.all} />
                  </p>
                  {tm?.quote && (
                    <blockquote>
                      <i class="icofont-quote-left quote-left"></i>
                      <p>
                        <Markup content={tm?.quote} />
                      </p>
                      <i class="las la-quote-right quote-right"></i>
                      <i class="icofont-quote-right quote-right"></i>
                    </blockquote>
                  )}
                  {tm?.paragraph && (
                    <p>
                      <Markup content={tm?.paragraph} />
                    </p>
                  )}
                </div>
              </article>
            </div>

            <div class="col-lg-4">
              <div class="sidebar">
                <h3 class="sidebar-title">Previous Feeds</h3>
                {previousEvent.map((event, key) => (
                  <div class="sidebar-item recent-posts">
                    <div class="post-item clearfix">
                      <img src={event?.image} alt="" />
                      <h4>
                        <a href={"/blog/" + event?.slug}>{event.title}</a>
                      </h4>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="text-center">
            <a href="/blog" class="btn-learn-more">
              Back To Blog
            </a>
          </div>
        </div>
      </section>
    </>
  );
}
