import { Helmet } from "react-helmet";
import slider from "../img/Contact.jpg";
import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";

export default function Booking() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <>
      <Helmet>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta http-equiv="X-UA-Compatible" content="ie=edge" />

        <title>Contact X Factor Productions Limited - Entertainment Company in Lagos</title>
        <meta name="description" content="Get in touch with XFP productions Limited for all your entertainment needs and production expertise." />
        <meta name="keywords" content="Contact XFP productions " />
        <link rel="canonical" href="http://xfactorproductions.ng/contact" />
      </Helmet>
      <section
        id="hero-1"
        data-parallax="scroll"
        style={{ backgroundImage: `url(${slider})` }}
      >
        <div className="title">
          <div className="container">
            <h2 style={{ color: "#fff" }}>
              {" "}
              Contact{" "}
            </h2>
          </div>
        </div>
      </section>
      <section id="contact" className="contact">
      <div className="container" data-aos="fade-up">

        <div className="section-title">
          <h2>Contact Us</h2>
          <p id="contact-title">Ready to join the XFP Productions family or inquire about our services? Get in touch with us today to learn more about how we can collaborate to create inclusive entertainment experiences that make a difference.  will love to hear from you!</p>
        </div>
      </div>

      <div data-aos="fade-up">
        <iframe style={{ border:"0", width: "100%", height: "350px" }} title="Contact Map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.046350044633!2d3.317237574182522!3d6.51581782325424!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8e8d3c34428d%3A0x697eb15188f1253a!2sThasious%20House!5e0!3m2!1sen!2sng!4v1685099488293!5m2!1sen!2sng" frameborder="0" allowfullscreen></iframe>
      </div>

      <div className="container" data-aos="fade-up">

        <div className="row mt-5">

          <div className="col-lg-4">
            <div className="info">
              <div className="address">
                <i className="bi bi-geo-alt"></i>
                <h4>Location:</h4>
                <p>THASIOUS HOUSE <br /> 120 Okota road, Okota Lagos.</p>
              </div>

              {/* <div className="open-hours">
                <i className="bi bi-clock"></i>
                <h4>Open Hours:</h4>
                <p>
                  Monday-Saturday:<br/>
                  11:00 AM - 2300 PM
                </p>
              </div> */}

              <div className="email">
                <i className="bi bi-envelope"></i>
                <h4>Email:</h4>
                <p>info@xfactorproductions.ng</p>
              </div>

              <div className="phone">
                <i className="bi bi-phone"></i>
                <h4>Call:</h4>
                <p> +2348137699078 <br/>  +2348034748552 <br/>  +447307955509 <br/>  +2349152803945 <br/>  +2349152803944 <br/>  +2349152803946 <br/>  +2349152803947</p>
              </div>

            </div>

          </div>
          <div className="col-lg-8 mt-5 mt-lg-0">

            <form action="forms/contact.php" method="post" role="form" className="php-email-form">
              <div className="row">
                <div className="col-md-6 form-group">
                  <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required/>
                </div>
                <div className="col-md-6 form-group mt-3 mt-md-0">
                  <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required/>
                </div>
              </div>
              <div className="form-group mt-3">
                <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" required/>
              </div>
              <div className="form-group mt-3">
                <textarea className="form-control" name="message" rows="8" placeholder="Message" required></textarea>
              </div>
              <div className="my-3">
                <div className="loading">Loading</div>
                <div className="error-message"></div>
                <div className="sent-message">Your message has been sent. Thank you!</div>
              </div>
              <div className="text-center"><button type="submit">Send Message</button></div>
            </form>

          </div>

        </div>

      </div>
    </section>
    </>
  );
}
