import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import AOS from "aos";
import "aos/dist/aos.css";
import slider from "../img/xchallenge.png";
import Axios from "axios";
import { Markup } from "interweave";

export default function ViewEvent() {
  const [tm, setTm] = useState();

  useEffect(() => {
    Axios.get(
      `https://api.xfactorproductions.ng/wp-json/wp/v2/upcomingevent`
    ).then((res) => {
      const { data } = res;
      setTm({
        quote: data[0].acf?.quote,
        name: data[0].title?.rendered,
        all: data[0].content?.rendered,
        yoast_head_title: data[0].yoast_head_json?.title,
        yoast_head_description: data[0].yoast_head_json?.description,
      });
    });
  }, []);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <>
      <Helmet>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta http-equiv="X-UA-Compatible" content="ie=edge" />
        
        <title>X Challenge Talent Hunt by XFP Productions Ltd.</title>
        <meta name="description" content="X Challenge is a talent hunt by XFP Productions, which provides a platform for talented disabled individuals in Nigeria to showcase their talents." />
        <meta name="keywords" content="X Challenge by XFP productions" />
        <link rel="canonical" href="https://xfactorproductions.ng/x-challenge" />
      </Helmet>

      <section
        id="challenge"
        style={{
          background: `url(${slider})`,
          backgroundPosition: "center center",
        }}
      ></section>

      <section class="challenge">
        <div class="container">
          <div class="row">
            <div class="col-xl-4 col-lg-4" data-aos="fade-up">
              <div class="content">
                <h3>XFP Productions Presents X Challenge Season 2</h3>
                <div class="text-center">
                  <a href="/login" class="more-btn">
                    Register/login <i class="bx bx-chevron-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-xl-8 col-lg-8 d-flex">
              <div class="icon-boxes d-flex flex-column justify-content-center">
                <div class="row">
                  <div
                    class="col-xl-4 d-flex align-items-stretch"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <div class="icon-box mt-4 mt-xl-0">
                      <i class="icofont-award"></i>
                      <h4>10 Million Naira</h4>
                      <p>WINNER</p>
                    </div>
                  </div>
                  <div
                    class="col-xl-4 d-flex align-items-stretch"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <div class="icon-box mt-4 mt-xl-0">
                      <i class="icofont-award"></i>
                      <h4>5 Million Naira</h4>
                      <p>1ST RUNNER UP</p>
                    </div>
                  </div>
                  <div
                    class="col-xl-4 d-flex align-items-stretch"
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    <div class="icon-box mt-4 mt-xl-0">
                      <i class="icofont-award"></i>
                      <h4>3 Million Naira</h4>
                      <p>2ND RUNNER UP</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="blog-single" class="blog">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 entries">
              <article class="entry entry-single">
                <h2 class="entry-title challenge">{tm?.name}</h2>

                <div class="entry-content">
                  <p>
                    <Markup content={tm?.all} />
                  </p>
                  {tm?.quote && (
                    <blockquote>
                      <i class="icofont-quote-left quote-left"></i>
                      <p>
                        <Markup content={tm?.quote} />
                      </p>
                      <i class="las la-quote-right quote-right"></i>
                      <i class="icofont-quote-right quote-right"></i>
                    </blockquote>
                  )}
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
