import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const SingleTalent = () => {
  let { id } = useParams();
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [summarize, setSummarize] = useState("");
  const [file, setFile] = useState("");

  useEffect(() => {
    const getTalentById = async () => {
      try {
        const res = await axios.get(`https://xfp.ng/api/v1/talent/${id}`);
        setFirstname(res.data.getTalentById.firstname);
        setLastname(res.data.getTalentById.lastname);
        setSummarize(res.data.getTalentById.summarize);
        setFile(res.data.getTalentById.file);
      } catch (error) {
        console.error("Error fetching talent data:", error);
      }
    };
    getTalentById();
  }, [id]);

  const handleApproved = async () => {
    try {
      const newdata = { status: "approved" };
      await axios.patch(`https://xfp.ng/api/v1/talent/${id}`, newdata);
      toast.success("Talent Accepted", { toastId: "msnjdndj" });
    } catch (error) {
      console.error("Error approving talent:", error);
    }
  };

  const handleRejected = async () => {
    try {
      const newdata = { status: "rejected" };
      await axios.patch(`https://xfp.ng/api/v1/talent/${id}`, newdata);
      toast.error("Talent Rejected", { toastId: id });
    } catch (error) {
      console.error("Error rejecting talent:", error);
    }
  };

  return (
    <section id="singleTalent">
      <div className="container">
        <div className="section-title">
          <h2>Talent</h2>
          <p>
            {" "}
            {firstname} {lastname}{" "}
          </p>
        </div>
        <div className="row content">
          <div className="col-md-4">
            <video
              controls
              className="img-fluid rounded"
              loop
              src={file}
              type="video/mp4"
            />
          </div>
          <div className="col-md-8">
            <p>{summarize}</p>
            <div className="mb-5 float-end">
              <button
                className="btn btn-success btn-md"
                onClick={handleApproved}
              >
                Approve
              </button>
              <button
                className="btn btn-danger btn-md ms-3"
                onClick={handleRejected}
              >
                Reject
              </button>
            </div>
          </div>
        </div>
        <div className="text-center">
            <a href="/dashboard/talents" class="btn-learn-more">
              Back To Talents
            </a>
          </div>
      </div>
    </section>
  );
};

export default SingleTalent;
