import { useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const Post = () => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [summarize, setSummarize] = useState("");
  const [selectFile, setSelectFile] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  let navigate = useNavigate()

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newData = {
      firstname,
      lastname,
      summarize,
      file: selectFile,
    };
    try {
      await axios.post(`https://xfp.ng/api/v1/talent/create`, newData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      toast.success("Your application was successfull!", { toastId: "msnjdndj" });
      setDisabled(true);
      setLoading(true);
      navigate("/x-challenge");
    } catch (error) {
      toast.error("Your application is failed", { toastId: "msnjdndj" });
      setDisabled(false);
      setLoading(false);
    }
  };

  return (
    <>
      <main class="col-lg-12 pt-5">
        <div className="container pt-5">
          <div className="row justify-content-md-center pt-1">
            <div className="col-md-4">
              <h1>Create New Talent</h1>
            </div>
            <div className="col-md-8">
              <form class="php-email-form" onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label for="validationCustom01" class="form-label">
                    First name
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="validationCustom01"
                    onChange={(e) => setFirstname(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label for="validationCustom02" class="form-label">
                    Last name
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="validationCustom02"
                    onChange={(e) => setLastname(e.target.value)}
                    required
                  />
                </div>
                <div class="mb-3">
                  <label for="floatingTextarea2" class="form-label">
                    Summary
                  </label>
                  <textarea
                    class="form-control"
                    placeholder="Leave a summary here"
                    id="floatingTextarea2"
                    onChange={(e) => setSummarize(e.target.value)}
                    style={{ height: "100px" }}
                  ></textarea>
                </div>
                <div class="mb-3">
                  <label for="formFile" class="form-label">
                    Video
                  </label>
                  <input
                    class="form-control"
                    type="file"
                    onChange={(e) => setSelectFile(e.target.files[0])}
                    id="formFile"
                  />
                </div>
                <button
                  type="submit"
                  disabled={disabled}
                  class="btn btn-primary"
                >
                  {loading ? "Loading" : "Submit"}{" "}
                </button>
              </form>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Post;
