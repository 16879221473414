import { useState } from "react";
import axios from 'axios'
import { useNavigate } from "react-router-dom";
import abountimage from "../img/dancer.png";


const Register = () => {

  const [username , setUsername] = useState("");
  const [email , setEmail] = useState("");
  const [password, setPassword] = useState("");

 let navigate = useNavigate()

  const handleSubmit = async (e) =>{
    e.preventDefault();

    const newUser = {
      username,
      email,
      password
    }

    try {
     await axios.post(`https://xfp.ng/api/v1/user/register`, newUser , {headers:{"Content-Type":"application/json"}})
     navigate('/login')
    } catch (error) {
      console.log(error)
    }
  }
   
    return (
      <>
      <section id="register" className="register">
        <div className="container">
          <div className="section-title">
            <h2>Register</h2>
            <p> For Xchallenge</p>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-lg-6 d-none d-lg-block bg-login-image">
              <img
                className="img-responsive img-login"
                src={abountimage}
                alt=""
              />
            </div>
            <div class="col-lg-6">
              <form class="user php-email-form" onSubmit={handleSubmit}>
                <div class="form-group mb-3">
                  <input
                    type="text"
                    class="form-control form-control-user"
                    id="exampleInputEmail"
                    onChange={(e) => setUsername(e.target.value)}
                    aria-describedby="emailHelp"
                    placeholder="Enter Full Name  "
                  />
                </div>
                <div class="form-group mb-3">
                  <input
                    type="email"
                    class="form-control form-control-user"
                    id="exampleInputEmail"
                    onChange={(e) => setEmail(e.target.value)}
                    aria-describedby="emailHelp"
                    placeholder="Enter Email Address..."
                  />
                </div>
                <div class="form-group">
                  <input
                    type="password"
                    class="form-control form-control-user"
                    id="exampleInputPassword"
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                  />
                </div>
                <div className="text-center">
                  <button type="submit">Register</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      </>
    );
  };
  
  export default Register;