import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import slider from "../img/xchallenge.png";
import slider1 from "../img/xchallenge-1.jpg";
import slider2 from "../img/xchallenge-2.jpg";
import abountimage from "../img/dancer.png";
import EventCard from "../components/Card";
import image from "../img/image-1.JPG";
import image1 from "../img/image-2.jpeg";
import image2 from "../img/image-3.jpeg";
import image3 from "../img/image-4.jpeg";
import image4 from "../img/image-5.jpeg";
import image5 from "../img/image-6.jpeg";
import image6 from "../img/image-7.jpeg";
import image7 from "../img/image-8.jpeg";
import worker from "../img/worker.png";
import AOS from "aos";
import "aos/dist/aos.css";
import Axios from "axios";
import client from "../img/clients/1.png";
import client1 from "../img/clients/2.png";
import client2 from "../img/clients/3.png";
import client3 from "../img/clients/4.jpeg";
import client4 from "../img/clients/5.jpeg";
import client5 from "../img/clients/6.jpeg";
import client6 from "../img/clients/7.jpg";
import client7 from "../img/clients/8.jpg";
import client8 from "../img/clients/9.jpg";

export default function Index() {
  const [eventList, setEventList] = useState([]);
  const [previousEvent, setPreviousEvent] = useState([]);

  useEffect(() => {
    Axios.get(`https://api.xfactorproductions.ng/wp-json/wp/v2/event`).then(
      (res) => {
        const { data } = res;
        let eventArray = [];
        data?.map((e) =>
          eventArray.unshift({
            id: e.id,
            title: e.title?.rendered,
            content: e.content?.rendered,
            date: e.date,
            image: e.better_featured_image?.source_url,
            guest: e.acf?.guest,
            slug: e.slug,
            except: e.acf?.role,
          })
        );
        setEventList(eventArray);
      }
    );
  }, []);

  useEffect(() => {
    Axios.get(`https://api.xfactorproductions.ng/wp-json/wp/v2/event`).then(
      (res) => {
        const { data } = res;
        let eventPrevious = [];
        data?.map((e) =>
          eventPrevious.push({
            id: e.id,
            title: e.title?.rendered,
            date: e.date,
            image: e.better_featured_image?.source_url,
            slug: e.slug,
          })
        );
        setPreviousEvent(eventPrevious.slice(0, 5));
      }
    );
  }, []);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <>
      <Helmet>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta http-equiv="X-UA-Compatible" content="ie=edge" />

        <title>
          XFP Productions Limited - Entertainment Company in Nigeria
        </title>
        <meta
          name="description"
          content="XFP Productions is a boutique entertainment company specializing in talent development, artists' management, and media consultancy services."
        />
        <meta name="keywords" content="XFP Productions" />
        <link rel="canonical" href="https://xfactorproductions.ng/" />
      </Helmet>

      <section id="hero">
        <div
          id="heroCarousel"
          class="carousel slide carousel-fade"
          data-ride="carousel"
        >
          <ol class="carousel-indicators" id="hero-carousel-indicators"></ol>

          <div class="carousel-inner" role="listbox">
            <div
              class="carousel-item active"
              style={{ backgroundImage: `url(${image})` }}
            >
              <div class="carousel-container">
                <div class="container">
                  <p data-aos="fade-down">
                  Welcome to XFP Productions, your one-stop destination for inclusive entertainment and talent management for Persons with Disabilities in Nigeria! 
                  </p>
                  <a
                    data-aos="fade-up"
                    href="/about"
                    class="btn-get-started scrollto"
                  >
                    Read More
                  </a>
                </div>
              </div>
            </div>

            <div
              class="carousel-item"
              style={{ backgroundImage: `url(${image1})` }}
            >
              <div class="carousel-container">
                <div class="container">
                  <p data-aos="fade-down">
                  At XFP Productions, we're dedicated to empowering Persons with Disabilities (PWDs) and creating opportunities for them to shine in the entertainment industry. 
                  </p>
                  <a
                    data-aos="fade-up"
                    href="/x-challenge"
                    class="btn-get-started scrollto"
                  >
                    Read More
                  </a>
                </div>
              </div>
            </div>

            <div
              class="carousel-item"
              style={{ backgroundImage: `url(${image3})` }}
            >
              <div class="carousel-container">
                <div class="container">
                  <p data-aos="fade-down">
                  Our mission is simple: to showcase the diverse talents of PWDs, challenge stereotypes, and foster a more inclusive society.
                  </p>
                  <a
                    data-aos="fade-up"
                    href="/x-challenge"
                    class="btn-get-started scrollto"
                  >
                    Read More
                  </a>
                </div>
              </div>
            </div>

            <div
              class="carousel-item"
              style={{ backgroundImage: `url(${image2})` }}
            >
              <div class="carousel-container">
                <div class="container">
                  <p data-aos="fade-down">
                  “Disabled lives are multi-faceted – brimming with personality, pride, ambition, love, and empathy. At XFP Productions, we pride and believe in equal opportunity and talents”. - Anthony Jay Williams, CEO XFP Productions
                  </p>
                  <a
                    data-aos="fade-up"
                    href="/x-challenge"
                    class="btn-get-started scrollto"
                  >
                    Read More
                  </a>
                </div>
              </div>
            </div>
          </div>

          <a
            class="carousel-control-prev"
            href="#heroCarousel"
            role="button"
            data-slide="prev"
          >
            <span
              class="carousel-control-prev-icon icofont-simple-left"
              aria-hidden="true"
            ></span>
            <span class="sr-only">Previous</span>
          </a>

          <a
            class="carousel-control-next"
            href="#heroCarousel"
            role="button"
            data-slide="next"
          >
            <span
              class="carousel-control-next-icon icofont-simple-right"
              aria-hidden="true"
            ></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
      </section>

      <main id="main">
        <section id="clients" class="clients">
          <div class="container">
            <div class="owl-carousel clients-carousel">
              <img src={client} alt="" />
              <img src={client1} alt="" />
              <img src={client2} alt="" />
              <img src={client3} alt="" />
              <img src={client4} alt="" />
              <img src={client5} alt="" />
              <img src={client6} alt="" />
              <img src={client7} alt="" />
              <img src={client8} alt="" />
            </div>
          </div>
        </section>

      </main>
    </>
  );
}
