import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Axios from "axios";
import { useParams } from "react-router";
import { Markup } from 'interweave';

export default function ViewEvent(props) {
  const [tm, setTm] = useState();
  const { slug } = useParams();

  useEffect(() => {
    Axios.get(
      `https://api.xfactorproductions.ng/wp-json/wp/v2/team/?slug=${slug}`
    ).then((res) => {
      const { data } = res;
      setTm({
        id: data[0].id,
        image: data[0].better_featured_image?.source_url,
        position: data[0].acf?.role,
        except: data[0].excerpt?.rendered,
        name: data[0].title?.rendered,
        all: data[0].content?.rendered,
        slug: data[0].slug,
        yoast_head_title: data[0].yoast_head_json?.title,
        yoast_head_description: data[0].yoast_head_json?.description,
        instagram: data[0].acf?.instagram,
        twitter: data[0].acf?.twitter,
        linkedin: data[0].acf?.linkedin,
        facebook: data[0].acf?.facebook,
      });
    });
  }, [slug]);
  return (
    <>
      <Helmet>
        <title>{tm?.yoast_head_title}</title>
        <meta name="description" content={tm?.yoast_head_description} />
        <link
          rel="canonical"
          href={`https://xfactorproductions.ng/team/${tm?.slug}`}
        />
      </Helmet>
      <section id="team-details">
      <div class="container">
        <div class="section-title">
          <h2>{tm?.name}</h2>
          <p>{tm?.position}</p>
        </div>

        <div class="row">
          <div class="col-md-4">
            <img src={tm?.image} alt={tm?.name} class="img-fluid"/>
          </div>

          <div class="col-md-8">
            <div class="details">
              <h2>{tm?.name}</h2>
              <div class="social">
                <a href={tm?.twitter} target="_blank" rel="noreferrer"><i class="fa fa-twitter"></i></a>
                <a href={tm?.facebook} target="_blank" rel="noreferrer"><i class="fa fa-facebook"></i></a>
                <a href={tm?.instagram} target="_blank" rel="noreferrer"><i class="fa fa-instagram"></i></a>
                <a href={tm?.linkedin} target="_blank" rel="noreferrer"><i class="fa fa-linkedin"></i></a>
              </div>
              <p><Markup content={tm?.all}/></p>
            </div>
          </div>

        </div>
      </div>

    </section>
    </>  
  );
}
