import image_1 from "./img/xchallenge/image2.jpeg";
import image_2 from "./img/xchallenge/image3.jpeg";
import image_3 from "./img/xchallenge/image4.jpeg";
import image_4 from "./img/xchallenge/image5.jpeg";
import image_5 from "./img/xchallenge/image6.jpeg";
import image_6 from "./img/xchallenge/image7.jpeg";
import image_7 from "./img/xchallenge/image8.jpeg";
import image_8 from "./img/xchallenge/image9.jpeg";
import image_9 from "./img/xchallenge/image12.jpeg";
import image_10 from "./img/xchallenge/image17.jpeg";
import image_11 from "./img/xchallenge/image22.jpeg";
import image_12 from "./img/xchallenge/image23.jpeg";
import image_13 from "./img/xchallenge/image25.jpeg";
import image_14 from "./img/xchallenge/image28.jpeg";
import image_15 from "./img/xchallenge/image30.jpeg";
import image_16 from "./img/xchallenge/image31.jpeg";
import image_17 from "./img/xchallenge/image32.jpeg";
import image_18 from "./img/xchallenge/image33.jpeg";
import image_19 from "./img/xchallenge/image36.jpeg";
import image_20 from "./img/xchallenge/image37.jpeg";
import image_21 from "./img/xchallenge/image38.jpeg";
import image_22 from "./img/xchallenge/image39.jpeg";
import image_23 from "./img/xchallenge/image40.jpeg";
import image_24 from "./img/xchallenge/image42.jpeg";
import image_25 from "./img/xchallenge/image47.jpeg";
import image_26 from "./img/xchallenge/image48.jpeg";
import image_27 from "./img/xchallenge/image49.jpeg";
import image_28 from "./img/xchallenge/image50.jpeg";
import image_29 from "./img/xchallenge/image51.jpeg";
import image_30 from "./img/xchallenge/image52.jpeg";
import image_31 from "./img/xchallenge/image54.jpeg";
import image_32 from "./img/xchallenge/image55.jpeg";
import image_33 from "./img/xchallenge/image56.jpeg";
import image_34 from "./img/xchallenge/image57.jpeg";
import image_35 from "./img/xchallenge/image58.jpeg";
import image_36 from "./img/xchallenge/image59.jpeg";
import image_37 from "./img/xchallenge/image60.jpeg";
import image_38 from "./img/xchallenge/image61.jpeg";
import image_39 from "./img/xchallenge/image62.jpeg";
import image_40 from "./img/xchallenge/image63.jpeg";
import image_41 from "./img/xchallenge/image64.jpeg";
import image_42 from "./img/xchallenge/image65.jpeg";
import image_43 from "./img/xchallenge/image66.jpeg";
import image_44 from "./img/xchallenge/image67.jpeg";
import image_45 from "./img/xchallenge/image68.jpeg";
import image_46 from "./img/xchallenge/image69.jpeg";
import image_47 from "./img/xchallenge/image70.jpeg";
import image_48 from "./img/xchallenge/image71.jpeg";
import image_49 from "./img/xchallenge/image72.jpeg";
import image_50 from "./img/xchallenge/image73.jpeg";
import image_51 from "./img/xchallenge/image74.jpeg";
import image_52 from "./img/xchallenge/image75.jpeg";
import image_53 from "./img/xchallenge/image76.jpeg";
import image_54 from "./img/xchallenge/image77.jpeg";
import image_55 from "./img/xchallenge/image78.jpeg";
import image_56 from "./img/xchallenge/image79.jpeg";
import image_57 from "./img/xchallenge/image80.jpeg";
import image_58 from "./img/xchallenge/image81.jpeg";
import image_59 from "./img/xchallenge/image82.jpeg";
import image_60 from "./img/xchallenge/image85.jpeg";
import image_61 from "./img/xchallenge/image86.jpeg";
import image_62 from "./img/xchallenge/image89.jpeg";
import image_63 from "./img/xchallenge/image90.jpeg";
import image_64 from "./img/xchallenge/image91.jpeg";
import image_65 from "./img/xchallenge/image92.jpeg";
import image_66 from "./img/xchallenge/image93.jpeg";
import image_67 from "./img/xchallenge/image94.jpeg";
import image_68 from "./img/xchallenge/image95.jpeg";
import image_69 from "./img/xchallenge/image98.jpeg";
import image_70 from "./img/xchallenge/image99.jpeg";
import image_71 from "./img/xchallenge/image103.jpeg";
import image_72 from "./img/xchallenge/image105.jpeg";
import image_73 from "./img/xchallenge/image106.jpeg";
import image_74 from "./img/xchallenge/image107.jpeg";
import image_75 from "./img/xchallenge/image108.jpeg";
import image_76 from "./img/xchallenge/image109.jpeg";
import image_77 from "./img/xchallenge/image110.jpeg";
import image_78 from "./img/xchallenge/image111.jpeg";
import image_79 from "./img/xchallenge/image112.jpeg";
import image_80 from "./img/xchallenge/image113.jpeg";
import image_81 from "./img/xchallenge/image114.jpeg";
import image_82 from "./img/xchallenge/image115.jpeg";
import image_83 from "./img/xchallenge/image116.jpeg";
import image_84 from "./img/xchallenge/image117.jpeg";
import image_85 from "./img/xchallenge/image118.jpeg";
import image_86 from "./img/xchallenge/image119.jpeg";
import image_87 from "./img/xchallenge/image120.jpeg";
import image_88 from "./img/xchallenge/image121.jpeg";
import image_89 from "./img/xchallenge/image122.jpeg";
import image_90 from "./img/xchallenge/image128.jpeg";

const images = [
  {
    key: 1,
    content: image_1 ,
  },
  {
    key: 2,
    content: image_2 ,
  },
  {
    key: 3,
    content:  image_3 ,
  },
  {
    key: 4,
    content:  image_4 ,
  },
  {
    key: 5,
    content:  image_5 ,
  },
  {
    key: 6,
    content:  image_6 ,
  },
  {
    key: 7,
    content:  image_7 ,
  },
  {
    key: 8,
    content:  image_8 ,
  },
  {
    key: 9,
    content:  image_9 ,
  },
  {
    key: 10,
    content:  image_10 ,
  },
  {
    key: 11,
    content:  image_11 ,
  },
  {
    key: 12,
    content:  image_12 ,
  },
  {
    key: 13,
    content:  image_13 ,
  },
  {
    key: 14,
    content:  image_14 ,
  },
  {
    key: 15,
    content:  image_15 ,
  },
  {
    key: 16,
    content:  image_16 ,
  },
  {
    key: 17,
    content:  image_17 ,
  },
  {
    key: 18,
    content:  image_18 ,
  },
  {
    key: 19,
    content:  image_19 ,
  },
  {
    key: 20,
    content:  image_20 ,
  },
  {
    key: 21,
    content:  image_21 ,
  },
  {
    key: 22,
    content:  image_22 ,
  },
  {
    key: 23,
    content:  image_23 ,
  },
  {
    key: 24,
    content:  image_24 ,
  },
  {
    key: 25,
    content:  image_25 ,
  },
  {
    key: 26,
    content:  image_26 ,
  },
  {
    key: 27,
    content:  image_27 ,
  },
  {
    key: 28,
    content:  image_28 ,
  },
  {
    key: 29,
    content:  image_29 ,
  },
  {
    key: 30,
    content:  image_30 ,
  },
  {
    key: 31,
    content:  image_31 ,
  },
  {
    key: 32,
    content:  image_32 ,
  },
  {
    key: 33,
    content:  image_33 ,
  },
  {
    key: 34,
    content:  image_34 ,
  },
  {
    key: 35,
    content:  image_35 ,
  },
  {
    key: 36,
    content:  image_36 ,
  },
  {
    key: 37,
    content:  image_37 ,
  },
  {
    key: 38,
    content:  image_38 ,
  },
  {
    key: 39,
    content:  image_39 ,
  },
  {
    key: 40,
    content:  image_40 ,
  },
  {
    key: 41,
    content:  image_41 ,
  },
  {
    key: 42,
    content:  image_42 ,
  },
  {
    key: 43,
    content:  image_43 ,
  },
  {
    key: 44,
    content:  image_44 ,
  },
  {
    key: 45,
    content:  image_45 ,
  },
  {
    key: 46,
    content:  image_46 ,
  },
  {
    key: 47,
    content:  image_47 ,
  },
  {
    key: 48,
    content:  image_48 ,
  },
  {
    key: 49,
    content:  image_49 ,
  },
  {
    key: 50,
    content:  image_50 ,
  },
  {
    key: 51,
    content:  image_51 ,
  },
  {
    key: 52,
    content:  image_52 ,
  },
  {
    key: 53,
    content:  image_53 ,
  },
  {
    key: 54,
    content:  image_54 ,
  },
  {
    key: 55,
    content:  image_55 ,
  },
  {
    key: 56,
    content:  image_56 ,
  },
  {
    key: 57,
    content:  image_57 ,
  },
  {
    key: 58,
    content:  image_58 ,
  },
  {
    key: 59,
    content:  image_59 ,
  },
  {
    key: 60,
    content:  image_60 ,
  },
  {
    key: 61,
    content:  image_61 ,
  },
  {
    key: 62,
    content:  image_62 ,
  },
  {
    key: 63,
    content:  image_63 ,
  },
  {
    key: 64,
    content:  image_64 ,
  },
  {
    key: 65,
    content:  image_65 ,
  },
  {
    key: 66,
    content:  image_66 ,
  },
  {
    key: 67,
    content:  image_67 ,
  },
  {
    key: 68,
    content:  image_68 ,
  },
  {
    key: 69,
    content:  image_69 ,
  },
  {
    key: 70,
    content:  image_70 ,
  },
  {
    key: 71,
    content:  image_71 ,
  },
  {
    key: 72,
    content:  image_72 ,
  },
  {
    key: 73,
    content:  image_73 ,
  },
  {
    key: 74,
    content:  image_74 ,
  },
  {
    key: 75,
    content:  image_75 ,
  },
  {
    key: 76,
    content:  image_76 ,
  },
  {
    key: 77,
    content:  image_77 ,
  },
  {
    key: 78,
    content:  image_78 ,
  },
  {
    key: 79,
    content:  image_79 ,
  },
  {
    key: 80,
    content:  image_80 ,
  },
  {
    key: 81,
    content:  image_81 ,
  },
  {
    key: 82,
    content:  image_82 ,
  },
  {
    key: 83,
    content:  image_83 ,
  },
  {
    key: 84,
    content:  image_84 ,
  },
  {
    key: 85,
    content:  image_85 ,
  },
  {
    key: 86,
    content:  image_86 ,
  },
  {
    key: 87,
    content:  image_87 ,
  },
  {
    key: 88,
    content:  image_88 ,
  },
  {
    key: 89,
    content:  image_89 ,
  },
  {
    key: 90,
    content:  image_90 ,
  },
];

export default images;
