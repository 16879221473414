import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import abountimage from "../img/dancer.png";

const Login = () => {
  const [admin, setAdmin] = useState("");
  const [password, setPassword] = useState("");

  let navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newAdmin = {
      admin,
      password,
    };

    try {
      const res = await axios.post(
        `https://xfp.ng/api/v1/admin/login`,
        newAdmin,
        { headers: { "Content-Type": "application/json" } }
      );

      let setJwt = localStorage.setItem(
        "token",
        JSON.stringify(res.data.access)
      );

      navigate("/dashboard/talents");
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <section id="login" className="login">
        <div className="container">
          <div className="section-title">
            <h2>Login</h2>
            <p>Xchallenge Dashboard</p>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-lg-6 d-none d-lg-block bg-login-image">
                <img className="img-responsive img-login" src={abountimage} alt="" />
            </div>
            <div class="col-lg-6">
              <form class="user php-email-form" onSubmit={handleSubmit}>
                <div class="form-group mb-3">
                  <input
                    type="email"
                    class="form-control form-control-user"
                    id="exampleInputEmail"
                    onChange={(e) => setAdmin(e.target.value)}
                    aria-describedby="emailHelp"
                    placeholder="Enter Email Address..."
                  />
                </div>
                <div class="form-group">
                  <input
                    type="password"
                    class="form-control form-control-user"
                    id="exampleInputPassword"
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                  />
                </div>
                <div className="text-center">
                  <button type="submit">Login</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
