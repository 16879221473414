import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import logo from "./img/logo-remove.png";
import Home from "./pages/Index.jsx";
import Events from "./pages/Events.jsx";
import ViewEvent from "./pages/ViewEvent.jsx";
import About from "./pages/About.jsx";
import Gallery from "./pages/Gallery.jsx";
import Booking from "./pages/Booking.jsx";
import Contact from "./pages/Contact.jsx";
import ViewTeam from "./pages/ViewTeam.jsx";
import Xchallenge from "./pages/xchallenge.jsx";
import Login from "./pages/login.jsx";
import Register from "./pages/register.jsx";
import Talents from "./pages/talents.jsx";
import Post from "./pages/post.jsx";
import PrivateRouteUser from "./components/authPrivateRouteUser.js";
import SingleTalent from "./pages/talent.jsx";
import Dashboard from "./pages/dashboard.jsx";

function App() {
  return (
    <>
    <Router>
      <header id="header" className="fixed-top">
        <div className="container d-flex align-items-center">
          <a href="/" className="logo mr-auto">
            <img src={logo} alt="" className="img-fluid" />
          </a>

          <nav className="nav-menu d-none d-lg-block">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <Link to="/gallery">Gallery</Link>
              </li>
              <li>
                <Link to="/blog">Blog</Link>
              </li>
              <li>
                <Link to="/x-challenge">Xchallenge</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </nav>
        </div>
      </header>

      <div style={{ overflowX: "hidden" }}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/team/:slug" element={<ViewTeam />} />
        <Route path="/blog" element={<Events />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/booking" element={<Booking />} />
        <Route path="/blog/:slug" element={<ViewEvent />} />
        <Route path="/x-challenge" element={<Xchallenge />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/dashboard/talents" element={<Talents />} />
        <Route path="/dashboard/talents/:id" element={<SingleTalent />} />
        <Route
          path="/post"
          element={
            <PrivateRouteUser>
              <Post />
            </PrivateRouteUser>
          }
        />
      </Routes>
      </div>

      <footer id="footer">
        <div class="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-4 footer-contact">
                <h3>
                  About<span>.</span>
                </h3>
                <p style={{ textAlign: "justify" }}>
                  XFP Productions is an entertainment company specializing in
                  talent development, artistes’ management, event planning and
                  media consultancy of persons with disabilities in Nigeria.
                </p>
              </div>

              <div className="col-lg-4 col-md-4 footer-contact">
                <h4>Contact Us</h4>
                <p>
                  {" "}
                  <i className="fa fa-map-marker" aria-hidden="true"></i>{" "}
                  THASIOUS HOUSE <br /> 120 Okota road, Okota Lagos.
                </p>
                <p className="contact-address">
                  <i className="fa fa-phone" aria-hidden="true"></i>{" "}
                  <a href="tel:+2348137699078">
                    +2348137699078 | +2348034748552 | +447307955509 |
                    +2349152803945 | +2349152803944 | +2349152803946 |
                    +2349152803947
                  </a>{" "}
                </p>
                <p className="contact-address">
                  <i className="fa fa-solid fa-envelope" aria-hidden="true"></i>{" "}
                  <a href="mailto:info@xfactorproductions.ng">
                    info@xfactorproductions.ng
                  </a>
                </p>
              </div>

              <div className="col-lg-4 col-md-4">
                <h4>Keep in touch</h4>
                <div class="social-links pt-4 pt-md-0">
                  <a
                    href="https://twitter.com/xfpng?t=IPeg1gi_RQiuy6b_tCWh1w&s=08"
                    class="twitter"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="bx bxl-twitter"></i>
                  </a>
                  <a
                    href="https://web.facebook.com/thexfactorproductions"
                    class="facebook"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="bx bxl-facebook"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/xfpng/"
                    class="instagram"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="bx bxl-instagram"></i>
                  </a>
                  <a
                    href="https://www.tiktok.com/@thexfpng"
                    class="tiktok"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="bx bxl-tiktok"></i>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/94845849/admin/?feedType=following"
                    class="linkedin"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="bx bxl-linkedin"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright text-center py-3">
          <p>
            {" "}
            Copyright &copy; {new Date().getFullYear()} XFP Productions Company.
            All Rights Reserved{" "}
          </p>
        </div>
      </footer>

      <a href="#" className="back-to-top">
        <i className="icofont-simple-up"></i>
      </a>
    </Router>
    <ToastContainer />
   </>
  );
}

export default App;
