import React from 'react'
import { Markup } from 'interweave';
export default function EventCard(event) {
    return (
        <>
            <article className="entry">
                <div className="entry-img">
                    <img src={event?.image} alt="" class="img-fluid" />
                </div>

                <h2 className="entry-title">
                    <a href={"/blog/" + event?.slug}>
                    {event?.title}
                    </a>
                </h2>

                <div className="entry-content">
                    <p>
                        <Markup content={event?.except + '[...]'}/>
                    </p>
                    <div className="read-more">
                        <a href={`/blog/${event?.slug}`}>Read More</a>
                    </div>
                </div>
            </article>
        </>
    )
}