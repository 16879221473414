import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import slider from "../img/Gallery.jpeg";
import AOS from "aos";
import images from "../image.js";

export default function Gallery() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <>
      <Helmet>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta http-equiv="X-UA-Compatible" content="ie=edge" />

        <title>XFP Productions Gallery - Capturing Moments</title>
        <meta name="description" content="Discover the true essence of entertainment at XFP productions" />
        <meta name="keywords" content="Gallery by XFP productions" />
        <link rel="canonical" href="https://xfactorproductions.ng/gallery" />
      </Helmet>

      <section
        id="hero-1"
        data-parallax="scroll"
        style={{ backgroundImage: `url(${slider})` }}
      >
        <div className="title">
          <div className="container">
            <h2 style={{ color: "#fff" }}> Gallery </h2>
          </div>
        </div>
      </section>
      <section id="portfolio" className="portfolio">
        <div className="container">
          <div class="section-title">
            <h2>Video Gallery</h2>
            <p>X Challenge talent hunt season 1</p>
          </div>
          <YoutubeMedia />
        </div>
        <div className="text-center">
          <a href="https://www.youtube.com/channel/UCmMylJooYnZJVztgbDxsznA" class="btn-learn-more">
            See More
          </a>
        </div>
      </section>

      <div className="container">
        <div class="section-title">
          <h2>Picture Gallery</h2>
          <p>X Challenge talent hunt season 1</p>
        </div>
      </div>

      <section id="picture">
        <div
          class="owl-carousel gallery-carousel"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          {images.map((image, key) => (
            <a href={image.content} class="venobox" data-gall="gallery-carousel">
              <img src={image.content} alt="" />
            </a>
          ))}
        </div>
      </section>
    </>
  );
}

const YoutubeMedia = () => {
  return (
    <div className="row">
        <div className="portfolio-list col-xs-12 col-md-4">
          <a href="https://youtu.be/Bk8qGRLpZLg" target="_blank" rel="noreferrer">
            <div className="th-mouse-portfolio-card" style={{ margin: 10 }}>
              <div className="thumbnail portfolio-thumbnail">
              <iframe width="100%" height="315" src="https://www.youtube.com/embed/Bk8qGRLpZLg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <div className="caption portfolio-caption">
                  <h3 className="portfolio-title">Breaking Barriers: Empowering Abilities in X Challenge Season One A Journey of Triumph and Inclusion</h3>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div className="portfolio-list col-xs-12 col-md-4">
          <a href="https://youtu.be/S2BG6vtXOn4" target="_blank" rel="noreferrer">
            <div className="th-mouse-portfolio-card" style={{ margin: 10 }}>
              <div className="thumbnail portfolio-thumbnail">
              <iframe width="100%" height="315" src="https://www.youtube.com/embed/S2BG6vtXOn4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <div className="caption portfolio-caption">
                  <h3 className="portfolio-title">Breaking Barriers: Unique Talents feature on News Central TV! Showing the season one of X Challenge.</h3>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div className="portfolio-list col-xs-12 col-md-4">
          <a href="https://youtu.be/r6N1CP-TQ1s" target="_blank" rel="noreferrer">
            <div className="th-mouse-portfolio-card" style={{ margin: 10 }}>
              <div className="thumbnail portfolio-thumbnail">
              <iframe width="100%" height="315" src="https://www.youtube.com/embed/r6N1CP-TQ1s" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <div className="caption portfolio-caption">
                  <h3 className="portfolio-title">Breaking Barriers: Extraordinary Talents feature on AIT News! Showing the season one of X Challenge.</h3>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div className="portfolio-list col-xs-12 col-md-4">
          <a href="https://youtu.be/dFE2uYvH_T8" target="_blank" rel="noreferrer">
            <div className="th-mouse-portfolio-card" style={{ margin: 10 }}>
              <div className="thumbnail portfolio-thumbnail">
              <iframe width="100%" height="315" src="https://www.youtube.com/embed/dFE2uYvH_T8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <div className="caption portfolio-caption">
                  <h3 className="portfolio-title">Breaking Barriers: Extraordinary Talents feature on TVC News! Showing the season one of X Challenge.</h3>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div className="portfolio-list col-xs-12 col-md-4">
          <a href="https://youtu.be/iAjnGAeIQb8" target="_blank" rel="noreferrer">
            <div className="th-mouse-portfolio-card" style={{ margin: 10 }}>
              <div className="thumbnail portfolio-thumbnail">
              <iframe width="100%" height="315" src="https://www.youtube.com/embed/iAjnGAeIQb8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <div className="caption portfolio-caption">
                  <h3 className="portfolio-title">Celebrating the Victorious Talent in Ability In Disability with our X Challenge S-1 winner Ruth</h3>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div className="portfolio-list col-xs-12 col-md-4">
          <a href="https://youtu.be/On-OQzGwj1c" target="_blank" rel="noreferrer">
            <div className="th-mouse-portfolio-card" style={{ margin: 10 }}>
              <div className="thumbnail portfolio-thumbnail">
              <iframe width="100%" height="315" src="https://www.youtube.com/embed/On-OQzGwj1c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <div className="caption portfolio-caption">
                  <h3 className="portfolio-title">Unleashing Unique Abilities: Honouring the Second Place Winner of the first ever X Challenge - Mr Eze</h3>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div className="portfolio-list col-xs-12 col-md-4">
          <a href="https://youtu.be/AvKgR239tuA" target="_blank" rel="noreferrer">
            <div className="th-mouse-portfolio-card" style={{ margin: 10 }}>
              <div className="thumbnail portfolio-thumbnail">
              <iframe width="100%" height="315" src="https://www.youtube.com/embed/AvKgR239tuA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <div className="caption portfolio-caption">
                  <h3 className="portfolio-title">Emmanuel Muna: Defying Limits, Inspiring Greatness in the X Challenge</h3>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div className="portfolio-list col-xs-12 col-md-4">
          <a href="https://youtu.be/3O1pvHtkL0E" target="_blank" rel="noreferrer">
            <div className="th-mouse-portfolio-card" style={{ margin: 10 }}>
              <div className="thumbnail portfolio-thumbnail">
              <iframe width="100%" height="315" src="https://www.youtube.com/embed/3O1pvHtkL0E" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <div className="caption portfolio-caption">
                  <h3 className="portfolio-title">Breaking barriers: X Challenge showcases the true talents of those with unique abilities. #Season 1</h3>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div className="portfolio-list col-xs-12 col-md-4">
          <a href="https://youtu.be/4tCe9-X-fdo" target="_blank" rel="noreferrer">
            <div className="th-mouse-portfolio-card" style={{ margin: 10 }}>
              <div className="thumbnail portfolio-thumbnail">
              <iframe width="100%" height="315" src="https://www.youtube.com/embed/4tCe9-X-fdo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <div className="caption portfolio-caption">
                  <h3 className="portfolio-title">Pushing Limits and Breaking Boundaries in the X Challenge Montage</h3>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div className="portfolio-list col-xs-12 col-md-4">
          <a href="https://youtu.be/zyVEHAuCSkE" target="_blank" rel="noreferrer">
            <div className="th-mouse-portfolio-card" style={{ margin: 10 }}>
              <div className="thumbnail portfolio-thumbnail">
              <iframe width="100%" height="315" src="https://www.youtube.com/embed/zyVEHAuCSkE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <div className="caption portfolio-caption">
                  <h3 className="portfolio-title">Breaking barriers: Empowering Voices, Celebrating Abilities, Enabling Democracy</h3>
                </div>
              </div>
            </div>
          </a>
        </div>
    </div>
  );
};
