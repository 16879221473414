import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import slider from "../img/About.jpeg";
import AOS from "aos";
import "aos/dist/aos.css";
import { FaConnectdevelop } from "react-icons/fa";
import { MdManageAccounts } from "react-icons/md";
import Axios from "axios";

export default function About() {
  const [teamList, setTeamList] = useState([]);

  useEffect(() => {
    Axios.get(`https://api.xfactorproductions.ng/wp-json/wp/v2/team?per_page=50`).then(
      (res) => {
        const { data } = res;
        let teamArray = [];
        data?.map((t) =>
          teamArray.unshift({
            id: t.id,
            image: t.better_featured_image?.source_url,
            position: t.acf?.role,
            except: t.excerpt?.rendered,
            name: t.title?.rendered,
            all: t.content.rendered,
            slug: t.slug,
          })
        );
        setTeamList(teamArray);
      }
    );
  }, [teamList]);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <>
      <Helmet>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta http-equiv="X-UA-Compatible" content="ie=edge" />

        <title>
          About XFP Productions Limited - Entertainment Company in Lagos
        </title>
        <meta
          name="description"
          content="XFP Productions Limited is a boutique entertainment company specializing in talent development, artists' management, + media consultancy services."
        />
        <meta name="keywords" content="XFP Productions Limited" />
        <link rel="canonical" href="https://xfactorproductions.ng/about" />
      </Helmet>
      <section
        id="hero-1"
        data-parallax="scroll"
        style={{ backgroundImage: `url(${slider})` }}
      >
        <div className="title">
          <div className="container">
            <h2 style={{ color: "#fff" }}> About </h2>
          </div>
        </div>
      </section>
      <section id="about-page" class="about-page">
        <div class="container">
          <div class="row">
            <div class="col-md-12 icon-boxes d-flex flex-column align-items-stretch justify-content-center ">
              <div class="icon-box" data-aos="fade-up">
                <div class="icon">
                  <i class="fa fa-users" aria-hidden="true"></i>
                </div>
                <h4 class="title">Who we are</h4>
                <p class="description">
                XFP Productions is a production company founded in 2021, with a passion for promoting diversity and inclusion in the entertainment industry among persons with disabilities in Nigeria. Our journey began with a commitment to championing the creative talents of PWDs and breaking down barriers to their participation. Over the years, we've grown into a leading force for change, producing groundbreaking events, documentaries, and talent showcases that celebrate the unique abilities of PWDs.
                </p>
                <p class="description">
                Through innovative programs and strategic partnerships, we aim to:
                <ul>
                  <li> <i class="bx bx-chevron-right"></i>Cultivate the artistic potential of PWDs across various entertainment disciplines.
Bridge the skills gap by equipping PWDs with the necessary tools to thrive in the entertainment industry, and beyond.
</li>
                  <li> <i class="bx bx-chevron-right"></i>Forge connections with industry professionals to open doors for meaningful employment opportunities for PWDs.</li>
                </ul>
                </p>
                <p class="description">
                At XFP Productions, we believe that true success lies in celebrating diversity and fostering a vibrant entertainment landscape and Labour Market that embraces talent without limitations.
                </p>
              </div>

              <div class="icon-box" data-aos="fade-up" data-aos-delay="100">
                <div class="icon">
                  <i class="fa fa-solid fa-bullseye" aria-hidden="true"></i>
                </div>
                <h4 class="title">Mission</h4>
                <p class="description">
                XFP Productions: Empowering PWD talent for a more inclusive Nigerian entertainment industry.
                </p>
              </div>

              <div class="icon-box" data-aos="fade-up" data-aos-delay="200">
                <div class="icon">
                  <i class="fa fa-solid fa-eye" aria-hidden="true"></i>
                </div>
                <h4 class="title">Vision</h4>
                <p class="description">
                XFP Productions envisions a thriving Nigerian entertainment industry where PWDs are celebrated stars, shaping the future of entertainment.
                </p>
              </div>

              {/* <div class="icon-box" data-aos="fade-up" data-aos-delay="300">
                <div class="icon">
                  <i class="fa fa-solid fa-handshake-o" aria-hidden="true"></i>
                </div>
                <h4 class="title">What Sets Us Apart</h4>
                <p class="description">
                XFP Productions is not just about entertainment; we are about empowering talent and fostering a more inclusive industry. We discover and develop exceptional artists across Africa, but with a specific focus on amplifying the voices of Persons with Disabilities (PWDs).
                </p>
                <p class="description">
                We go beyond traditional talent management. Through impactful initiatives like interviews and documentaries, we showcase the inspiring stories and artistic talents of PWDs. This not only raises awareness but also paves the way for tangible opportunities and increased inclusivity within the entertainment industry. We are building a future where exceptional talent shines, regardless of ability.
                </p>
              </div> */}

            </div>
          </div>
        </div>
      </section>
      <section id="services" class="services section-bg ">
        <div class="container" data-aos="fade-up">
          <div class="section-title">
            <h2 className="services-title">Our Services</h2>
            <p className="services-subtitle">Check our Services</p>
          </div>

          <div class="row">
            {/* <div class="col-md-6">
              <div class="icon-box" data-aos="fade-up" data-aos-delay="100">
                <i>
                  <FaConnectdevelop />
                </i>
                <h4>
                  <a href="/booking">Talent development</a>
                </h4>
                <p>
                  XFP Productions works to develop and refine your talent. We
                  champion designing effective and strategic initiatives that
                  set you apart from the masses and up for success.
                </p>
              </div>
            </div> */}
            <div class="col-md-6">
              <div class="icon-box" data-aos="fade-up" data-aos-delay="200">
                <i>
                  <MdManageAccounts />
                </i>
                <h4>
                  <a href="#">Talent management</a>
                </h4>
                <p>
                We represent a diverse roster of talented artists, musicians, actors, and performers, including PWDs, providing them with the support and resources they need to succeed in the industry.
                </p>
              </div>
            </div>
            <div class="col-md-6 mt-4 mt-md-0">
              <div class="icon-box" data-aos="fade-up" data-aos-delay="300">
                <i class="icofont-calendar"></i>
                <h4>
                  <a href="#">Event planning</a>
                </h4>
                <p>
                From talent shows and concerts to film screenings and award ceremonies, we specialize in creating unforgettable events that showcase the talents of PWDs and promote inclusion.
                </p>
              </div>
            </div>
            <div class="col-md-6 mt-4 mt-md-0">
              <div class="icon-box" data-aos="fade-up" data-aos-delay="400">
                <i class="icofont-multimedia"></i>
                <h4>
                  <a href="#">Media consultancy</a>
                </h4>
                <p>
                Our team of experts offers strategic media consulting services to help organizations and brands navigate the complexities of inclusive marketing and representation in the media.
                </p>
              </div>
            </div>
          </div>
          {/* <div className="text-center">
            <a href="/booking" class="btn-learn-more">
              Book a service
            </a>
          </div> */}
        </div>
      </section>

     <section id="team" class="team">
        <div class="container" data-aos="fade-up">
          <div class="section-title">
            <h2>Our Team</h2>
            <p>Check our Team</p>
          </div>

          <div class="row">
            {teamList.map((team, i) => (
              <div key={i} class="col-lg-4 col-md-6 d-flex align-items-stretch">
                <div class="member" data-aos="fade-up" data-aos-delay="100">
                  <div class="member-img">
                    <img src={team.image} className="img-fluid" alt="" />
                  </div>
                  <div class="member-info">
                    <h4>
                      {" "}
                      <a href={`/team/${team?.slug}`}>{team.name}</a>{" "}
                    </h4>
                    <span>{team.position}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section id="why-us" class="why-us">
        <div class="container" data-aos="fade-up">
          <div class="section-title">
            <h2>Our Core Values</h2>
            <p>What Makes Us Different</p>
          </div>

          <div class="row">
            <div class="col-lg-4">
              <div class="box" data-aos="zoom-in" data-aos-delay="100">
                <span>01</span>
                <h4>INNOVATION</h4>
                <p>
                  We are committed to finding better ways of doing business
                  every day. We create, innovate and embrace change for growth
                  and better opportunities
                </p>
              </div>
            </div>

            <div class="col-lg-4 mt-4 mt-lg-0">
              <div class="box" data-aos="zoom-in" data-aos-delay="200">
                <span>02</span>
                <h4>INDIVIDUAL AND TEAM EFFICIENCY</h4>
                <p>
                  We operate in an environment of trust and collaboration. We
                  are loyal to one another, reward improvements inspired by
                  individual ingenuity and celebrate positive results brought
                  about by team effort
                </p>
              </div>
            </div>

            <div class="col-lg-4 mt-4 mt-lg-0">
              <div class="box" data-aos="zoom-in" data-aos-delay="300">
                <span>03</span>
                <h4> ETHICAL CONDUCT</h4>
                <p>
                  We are committed to conducting business with unwavering
                  integrity, honesty, and respect for all partners & sponsors.
                  We comply with applicable laws and regulations, promote fair
                  competition, and prioritize environmental responsibility. We
                  safeguard data privacy and security, foster responsible supply
                  chain practices, and actively contribute to the betterment of
                  our communities.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
